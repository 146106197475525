import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StatisticsState {
  numGames: number;
  totalWords: number;
  totalLetters: number;
  totalScore: number;
}

const initialState: StatisticsState = {
  numGames: 0,
  totalWords: 0,
  totalLetters: 0,
  totalScore: 0
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    increaseNumGames: state => {
      state.numGames += 1;
    },
    increaseTotalWords: (state, action: PayloadAction<number>) => {
      state.totalWords += action.payload;
    },
    increaseTotalLetters: (state, action: PayloadAction<number>) => {
      state.totalLetters += action.payload;
    },
    increaseTotalScore: (state, action: PayloadAction<number>) => {
      state.totalScore += action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { increaseNumGames, increaseTotalWords, increaseTotalLetters, increaseTotalScore } = statisticsSlice.actions;

export default statisticsSlice.reducer;

import { type Grid } from "../GridGenerator/Types";

const END_OF_WORD_MARKER = "--END_OF_WORD--";

interface Tree {
  [key: string]: Tree;
}

const dictionaryCache: { [key: string]: {} | undefined } = {};

export function findWords(grid: Grid, dictionary: string[], minimumWordLength = 1): string[] {
  const dictionaryKey = dictionary.join();
  let tree = dictionaryCache[dictionaryKey];
  if (!tree) {
    tree = {};
    makeTree(
      dictionary.filter(word => word.length >= minimumWordLength),
      0,
      tree
    );

    dictionaryCache[dictionaryKey] = tree;
  }

  const foundWords: string[] = [];
  for (let y = 0; y < grid.length; ++y) {
    for (let x = 0; x < grid[y].length; ++x) {
      findWordsStartingAt("", grid, x, y, [], foundWords, tree);
    }
  }

  return foundWords;
}

function findWordsStartingAt(
  wordSoFar: string,
  grid: Grid,
  xStart: number,
  yStart: number,
  coordinatesUsedAlready: { x: number; y: number }[],
  wordsFoundAlready: string[],
  wordTree: Tree
) {
  wordSoFar += grid[yStart][xStart].text;
  coordinatesUsedAlready.push({ x: xStart, y: yStart });
  for (let y = yStart - 1; y <= yStart + 1; ++y) {
    if (y < 0 || y >= grid.length) {
      continue;
    }

    for (let x = xStart - 1; x <= xStart + 1; ++x) {
      if (x < 0 || x >= grid[y].length) {
        continue;
      }

      if (isWord(wordSoFar, wordTree) && !wordsFoundAlready.includes(wordSoFar)) {
        wordsFoundAlready.push(wordSoFar);
      }

      if (coordinatesUsedAlready.find(coord => coord.y === y && coord.x === x)) {
        continue;
      }

      if (doAnyWordsExistStartingWith(wordSoFar, wordTree)) {
        findWordsStartingAt(wordSoFar, grid, x, y, [...coordinatesUsedAlready], wordsFoundAlready, wordTree);
      }
    }
  }
}

function makeTree(dictionary: string[], depth: number, wordTree: Tree) {
  for (const word of dictionary) {
    if (word.length > depth) {
      let branch = wordTree[word[depth]];
      if (!branch) {
        branch = {};
        wordTree[word[depth]] = branch;
      }

      if (depth === word.length - 1) {
        branch[END_OF_WORD_MARKER] = {};
      }

      makeTree([word], depth + 1, branch);
    }
  }
}

function doAnyWordsExistStartingWith(prefix: string, wordTree: Tree) {
  let branch = wordTree;
  for (const character of prefix) {
    branch = branch[character];
    if (!branch) {
      return false;
    }
  }

  return true;
}

function isWord(word: string, tree: Tree) {
  let branch = tree;
  for (const character of word) {
    branch = branch[character];
    if (!branch) {
      return false;
    }
  }

  return branch[END_OF_WORD_MARKER] != null;
}

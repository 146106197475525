import React from "react";

import { GRID_SIZE } from "../../AppConstants";
import appInsights from "../../ApplicationInsightsWrapper";
import { useAppDispatch, useAppSelector, useDimensions, useResource } from "../../hooks";
import { increaseNumGames, increaseTotalLetters, increaseTotalScore, increaseTotalWords } from "../Statistics/StatisticsSlice";
import { addCompletedGame } from "./GameSlice";

import { BigButton } from "../BigButton/BigButton";
import { Countdown } from "../Countdown/Countdown";
import { CountdownToNextGame } from "../CountdownToNextGame/CountdownToNextGame";
import { LetterGrid } from "../LetterGrid/LetterGrid";
import { ShareButton } from "../ShareButton/ShareButton";
import { Spinner } from "../Spinner/Spinner";

import { calculateScore } from "../../features/Game/ScoreCalculator";
import { findWords } from "../../features/Game/WordFinder";
import { generateGrid } from "../../features/GridGenerator/GridGenerator";
import { type Grid, type Letter } from "../../features/GridGenerator/Types";

import { ReactComponent as RotateIcon } from "../../assets/svgs/rotate_right_24dp.svg";

import Resources from "./GameResources.json";

import classes from "./Game.module.css";

const INITIAL_TIME_LIMIT = 60_000;
const MIN_WORD_LENGTH = 3;
const isRetry = window.location.search.includes("retry=t");

const scoreEmojis = {
  0: "0️⃣",
  1: "1️⃣",
  2: "2️⃣",
  3: "3️⃣",
  4: "4️⃣",
  5: "5️⃣",
  6: "6️⃣",
  7: "7️⃣",
  8: "8️⃣",
  9: "9️⃣",
} as { [key: number]: string };

export interface GameProps {
  grid: Grid;
  title?: string;
  themeWords?: string[];
  dictionary: string[];
  gameNumber: number;
  language: string;
}

export const Game = ({ grid, title, themeWords, dictionary, gameNumber: gameNumberProp, language }: GameProps): JSX.Element => {
  const completedGames = useAppSelector((state) => state.game.completedGames);
  const isLeftHandedMode = useAppSelector((state) => state.app.isLeftHandedMode);
  const dispatch = useAppDispatch();
  const dimensions = useDimensions();
  const [isPractiseMode, setIsPractiseMode] = React.useState(false);
  const [practiseGrid, setPractiseGrid] = React.useState<Grid>([]);
  const [isPractiseForceFinished, setIsPractiseForceFinished] = React.useState(false);
  const [gameNumber, setGameNumber] = React.useState(gameNumberProp);
  const [gridRotation, setGridRotation] = React.useState(0);
  const [timeRemaining, setTimeRemaining] = React.useState(INITIAL_TIME_LIMIT);
  const [startTime, setStartTime] = React.useState(0);
  const [foundWords, setFoundWords] = React.useState<string[]>([]);
  const [score, setScore] = React.useState(0);
  const [isShareButtonShowing, setIsShareButtonShowing] = React.useState(false);
  const [bestWord, setBestWord] = React.useState({ word: "", score: 0 });
  const [successLetters, setSuccessLetters] = React.useState<Letter[]>([]);
  const [duplicateLetters, setDuplicateLetters] = React.useState<Letter[]>([]);
  const [failureLetters, setFailureLetters] = React.useState<Letter[]>([]);
  const [possibleWords, setPossibleWords] = React.useState<string[]>([]);
  const startText = useResource(Resources, "start");
  const practiseModeText = useResource(Resources, "practise");
  const bestWordText = useResource(Resources, "bestWord");
  const scoreText = useResource(Resources, "score");
  const possibleWordsTitle = useResource(Resources, "possibleWordsTitle");
  const newGridText = useResource(Resources, "newGrid");
  const finishText = useResource(Resources, "finish");
  const completedGame = isRetry ? null : completedGames?.find((x) => x.gameNumber === gameNumber && x.language === language);
  const isGameComplete = !!completedGame || (isPractiseMode && isPractiseForceFinished);
  const hasStarted = startTime > 0 || isGameComplete;
  const hasFinished = timeRemaining <= 0 || isGameComplete;

  const scoreString = score.toString();
  let scoreEmojiString = "";
  for (let i = 0; i < scoreString.length; ++i) {
    scoreEmojiString += scoreEmojis[+scoreString[i]];
  }

  const shareData: ShareData = {
    title: `Scrambl #${gameNumber}`,
    text: `Scrambl #${gameNumber}
${useResource(Resources, "score")} ${scoreEmojiString}
${useResource(Resources, "bestWordLength")} ${bestWord.word.length}
${useResource(Resources, "bestWordScore")} ${bestWord.score}`,
  };

  const resetPractiseGrid = async () => {
    setPractiseGrid([]);
    setPractiseGrid(await generateGrid(GRID_SIZE, language, dictionary));
  };

  React.useEffect(() => {
    setGameNumber(gameNumberProp);
  }, [gameNumberProp]);

  React.useEffect(() => {
    if (completedGame) {
      setScore(completedGame.score);
      setBestWord({ word: completedGame.bestWord, score: completedGame.bestWordScore });
    }
  }, [completedGame]);

  React.useEffect(() => {
    if (!isPractiseMode) {
      if (hasStarted && timeRemaining > 0) {
        if (timeRemaining > 6000) {
          const elapsedTime = window.performance.now() - startTime;
          // Don't tick as quickly if there's a long time to go.
          window.setTimeout(() => {
            setTimeRemaining(hasFinished ? 0 : INITIAL_TIME_LIMIT - elapsedTime);
          }, 250);
        } else {
          window.requestAnimationFrame(() => {
            const elapsedTime = window.performance.now() - startTime;
            setTimeRemaining(hasFinished ? 0 : INITIAL_TIME_LIMIT - elapsedTime);
          });
        }
      }
    }
  }, [hasStarted, hasFinished, startTime, timeRemaining, isPractiseMode]);

  React.useEffect(() => {
    if (!isPractiseMode) {
      if (hasFinished && score > 0) {
        if (score > 0) {
          window.setTimeout(() => setIsShareButtonShowing(true), 1200);
        }
      }

      if (hasFinished && !completedGames.find((x) => x.gameNumber === gameNumber && x.language === language)) {
        dispatch(increaseNumGames());
        dispatch(increaseTotalLetters(foundWords.reduce((prev, curr) => prev + curr.length, 0)));
        dispatch(increaseTotalScore(score));
        dispatch(increaseTotalWords(foundWords.length));
        dispatch(addCompletedGame({ gameNumber, language, bestWord: bestWord.word, bestWordScore: bestWord.score, score }));

        appInsights.trackEvent({ name: "GameCompleted" }, { bestWordScore: bestWord.score, score, language, gameNumber });
      }
    }
  }, [score, foundWords, gameNumber, language, hasFinished, bestWord, completedGames, isPractiseMode, dispatch]);

  const fillPossibleWords = React.useCallback(() => {
    // Do this after a small delay as it's a performance bottleneck. The wait should allow
    // all the other drawing/painting to complete before this kicks in.
    return window.setTimeout(() => {
      if (!hasFinished) {
        setPossibleWords([]);
      } else {
        const allFoundWords = findWords(isPractiseMode ? practiseGrid : grid, dictionary).sort(sorter);
        setPossibleWords(
          (themeWords?.filter((themeWord) => allFoundWords.includes(themeWord)).sort(sorter) || []).concat(
            allFoundWords.filter((word) => word.length >= MIN_WORD_LENGTH && !themeWords?.includes(word))
          )
        );
      }
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid, dictionary, foundWords, hasFinished]);

  React.useEffect(() => {
    if (hasFinished && dictionary?.length > 0) {
      const timeoutId = fillPossibleWords();
      return function cleanUp() {
        window.clearTimeout(timeoutId);
      };
    }
  }, [hasFinished, dictionary?.length, fillPossibleWords]);

  const activeGrid = isPractiseMode ? practiseGrid : grid;
  return (
    <>
      {hasStarted && !isPractiseMode && title && <h2 className={`${classes.title}`}>{title}</h2>}
      <div
        className={`${classes.gameContainer} ${hasStarted ? classes.started : ""} ${
          isLeftHandedMode ? classes.leftHandedMode : ""
        }`}
      >
        <div className={classes.letterGridContainer}>
          {!activeGrid?.length && !activeGrid?.[0]?.length && <Spinner />}

          {!hasStarted && grid?.length > 0 && grid?.[0].length > 0 && (
            <div>
              <BigButton type="button" title={startText} onClick={() => setStartTime(window.performance.now())}>
                {startText}
              </BigButton>
              <br />
              <BigButton
                type="button"
                title={practiseModeText}
                style={{ fontSize: "var(--font-size-normal)", marginTop: "2em" }}
                onClick={async () => {
                  setIsPractiseMode(true);
                  setStartTime(window.performance.now());
                  await resetPractiseGrid();
                }}
              >
                {practiseModeText}
              </BigButton>
            </div>
          )}

          {hasStarted && grid?.length > 0 && grid?.[0].length > 0 && (
            <LetterGrid
              rotation={gridRotation}
              letters={isPractiseMode ? practiseGrid : grid}
              successLetters={successLetters}
              duplicateLetters={duplicateLetters}
              failureLetters={failureLetters}
              isDisabled={hasFinished}
              onWordFinished={(letters) => {
                setDuplicateLetters([]);
                setFailureLetters([]);
                setSuccessLetters([]);

                if (hasFinished) {
                  return;
                }

                const proposedWord = letters.reduce((prev, curr) => prev + curr.text, "");
                const scoreForWord = calculateScore(letters, dictionary, themeWords);
                if (scoreForWord <= 0 && proposedWord.length >= MIN_WORD_LENGTH) {
                  setFailureLetters(letters);
                }

                const isDuplicate = foundWords.includes(proposedWord);
                if (isDuplicate) {
                  setDuplicateLetters(letters);
                }

                if (scoreForWord <= 0 || isDuplicate) {
                  return;
                }

                setFoundWords([...foundWords, proposedWord]);
                setScore(score + scoreForWord);
                setSuccessLetters(letters);
                if (scoreForWord > bestWord.score) {
                  setBestWord({ word: proposedWord, score: scoreForWord });
                }

                if (themeWords?.includes(proposedWord)) {
                }
              }}
            />
          )}
        </div>

        {hasStarted && (
          <div className={`${classes.infoPanel} ${hasStarted && !isGameComplete ? classes.noSelect : ""}`}>
            <div className={classes.score}>
              {scoreText} {scoreEmojiString}
            </div>
            {(score > 0 || !hasFinished) && !isPractiseMode && <Countdown timeRemaining={timeRemaining} />}

            <div className={`${classes.bestWord} muted`} style={{ visibility: bestWord.score > 0 ? "visible" : "hidden" }}>
              {bestWordText} {bestWord.word} ({bestWord.score})
            </div>
            {!hasFinished && (
              <div className={classes.rotateButton}>
                <BigButton onClick={() => setGridRotation(gridRotation + 90)} style={{ fontSize: "var(--font-size-normal)" }}>
                  <RotateIcon />
                </BigButton>
              </div>
            )}

            {isPractiseMode && (
              <div className={classes.practisePanel}>
                <BigButton
                  type="button"
                  additionalClasses={classes.bigButtonPrimary}
                  onClick={async () => {
                    await resetPractiseGrid();
                    setIsPractiseForceFinished(false);
                    setScore(0);
                    setBestWord({ word: "", score: 0 });
                    setFoundWords([]);
                  }}
                >
                  {newGridText}
                </BigButton>
                <BigButton
                  type="button"
                  additionalClasses={classes.bigButtonPrimary}
                  disabled={isPractiseForceFinished}
                  onClick={() => {
                    setIsPractiseForceFinished(true);
                  }}
                >
                  {finishText}
                </BigButton>

                <BigButton
                  type="button"
                  title={startText}
                  onClick={() => {
                    setStartTime(window.performance.now());
                    setIsPractiseMode(false);
                    setScore(0);
                    setBestWord({ word: "", score: 0 });
                    setFoundWords([]);
                  }}
                >
                  {startText}
                </BigButton>
              </div>
            )}

            {!isPractiseMode && (
              <div className={`${classes.shareButtonContainer} ${isShareButtonShowing ? classes.shareButtonShowing : ""}`}>
                <ShareButton shareData={shareData}>Share</ShareButton>
              </div>
            )}
          </div>
        )}
      </div>

      {hasFinished && dictionary?.length > 0 && (
        <div className={classes.appear}>
          {!isPractiseMode && <CountdownToNextGame showingGameNumber={gameNumber} />}
          <h4 className={classes.possibleWordsTitle}>{possibleWordsTitle}</h4>
          <div className={`${classes.possibleWordList} ${dimensions.isDesktop ? classes.desktop : ""}`}>
            {possibleWords.map((word) => {
              const Tag: keyof JSX.IntrinsicElements = foundWords.includes(word) || bestWord.word === word ? "b" : "span";
              return (
                <Tag key={word} style={{ fontStyle: themeWords?.includes(word) ? "italic" : undefined }}>
                  {word}
                </Tag>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

function sorter(a: string, b: string) {
  return a.length > b.length ? -1 : a.length === b.length ? a.localeCompare(b, undefined, { sensitivity: "base" }) : 1;
}

import { useAppSelector, useResource } from "../../hooks";

import classes from "./Statistics.module.css";

import Resources from "./StatisticsResources.json";

export const Statistics = (): JSX.Element => {
  const stats = useAppSelector(state => state.statistics);
  return (
    <div className={classes.statsContainer}>
      <div className={classes.padRight}>{useResource(Resources, "numGames")}</div>
      <strong>{stats.numGames}</strong>

      <div className={classes.padRight}>{useResource(Resources, "totalWords")}</div>
      <strong>{stats.totalWords}</strong>

      <div className={classes.padRight}>{useResource(Resources, "averageWords")}</div>
      <strong>{(stats.totalWords / (stats.numGames || 1)).toFixed(2)}</strong>

      <div className={classes.padRight}>{useResource(Resources, "averageLetters")}</div>
      <strong>{(stats.totalLetters / (stats.totalWords || 1)).toFixed(2)}</strong>

      <div className={classes.padRight}>{useResource(Resources, "totalScore")}</div>
      <strong>{stats.totalScore}</strong>

      <div className={classes.padRight}>{useResource(Resources, "averageScore")}</div>
      <strong className={classes.averageScore}>{(stats.totalScore / (stats.numGames || 1)).toFixed(2)}</strong>
    </div>
  );
};

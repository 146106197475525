import { type Alphabet, type Grid, type Letter, type IncompleteGrid } from "./Types";
import { getAlphabet } from "./Alphabets";
import { findWords } from "../Game/WordFinder";

const LONG_WORD_LENGTH = 12;

export interface GenerationOptions {
  minimumWordCount: number;
  minimumWordLength: number;
  isCancelled?: boolean;
}

export function generateGrid(
  size: number,
  languageOrAlphabet: string | Alphabet,
  dictionary: string[],
  options?: GenerationOptions
): Promise<Grid> {
  return generateGridWithWords(size, languageOrAlphabet, dictionary, options).then(x => x.grid);
}

export function generateGridWithWords(
  size: number,
  languageOrAlphabet: string | Alphabet,
  dictionary: string[],
  options?: GenerationOptions
): Promise<{ grid: Grid; words: string[] }> {
  const CANCELLATION_MESSAGE = "Grid generation was cancelled.";
  if (options?.isCancelled) {
    return Promise.reject(CANCELLATION_MESSAGE);
  }

  const { minimumWordCount = 200 } = options || {};
  const minimumWordLength = Math.min(options?.minimumWordLength || 9, size * size);

  return new Promise((resolve, reject) => {
    const iterationCallback = () => {
      if (options?.isCancelled) {
        reject(CANCELLATION_MESSAGE);
        return;
      }

      const grid = generateIteration(size, languageOrAlphabet, dictionary);
      const possibleWords = findWords(grid, dictionary);
      if (
        (possibleWords.length >= minimumWordCount && possibleWords.some(x => x.length >= minimumWordLength)) ||
        !dictionary?.length
      ) {
        resolve({ grid, words: possibleWords });
      } else {
        window.setTimeout(iterationCallback, 0);
      }
      return { grid, words: possibleWords } as const;
    };

    window.setTimeout(iterationCallback, 0);
  });
}

function generateIteration(size: number, languageOrAlphabet: string | Alphabet, dictionary: string[]) {
  const alphabet = typeof languageOrAlphabet === "string" ? getAlphabet(languageOrAlphabet) : languageOrAlphabet;
  const allLetters = [...alphabet.vowels, ...alphabet.consonants];
  const numGridSlots = size * size;
  const longWords = dictionary.filter(word => word.length >= Math.min(LONG_WORD_LENGTH, numGridSlots));

  // Rule 1: Pick a long word from the dictionary and use that as a starting point.
  const generatedLetters: Letter[] = generateLetters(size, allLetters, longWords);

  // Rule 2: There has to be at least `size` vowels from the language. Normally 4.
  ensureVowels(size, generatedLetters, alphabet);

  // Rule 3: Grab the entire alphabet and fill up the rest of the slots. Favor lower scoring letters.
  fillRemainingSlots(numGridSlots, generatedLetters, allLetters);

  // Rule 4: Not more than 40% of the letters can be vowels.
  removeExcessVowels(numGridSlots, generatedLetters, alphabet);

  // Rule 5: Randomize the positions of the letters.
  let grid: IncompleteGrid = [];
  randomizePositions(size, generatedLetters, grid);

  // Rule 6: A vowel must sit in at least one of the center cells. Determined by the square root of the grid size.
  // If we need to insert a vowel here, we'll swap the consonant with the nearest vowel.
  ensureVowelInMiddle(alphabet.vowels, grid);

  // Rule 7 (TODO): Lucky dip one of the slots for bonus points!
  createBonusSlot(grid);

  // Rule 8: Replace quirks. E.g. If English, replace 'Q' with 'QU'
  replaceLanguageQuirks(alphabet, grid);

  // Rule 9: While there's more than one tile with a multiple letters, replace one of them.
  cullExcessMultiletterTiles(allLetters, grid);

  // Clone the grid so that any subsequent code can't accidentally modify the original alphabet.
  grid = grid.map(row => row.map(letter => ({ ...letter })));

  return grid;
}

export function generateLetters(size: number, allLetters: Letter[], longWords: string[]) {
  const numGridSlots = size * size;
  const allCharacters = allLetters.map(x => x.text);
  const generatedLetters: Letter[] = [];
  const randomLongWord = longWords.length >= 1 ? longWords[Math.trunc(Math.random() * longWords.length)] : "";
  let i: number = 0;
  for (; i < Math.min(randomLongWord.length, numGridSlots); ++i) {
    const letter = randomLongWord[i];
    if (allCharacters.includes(letter)) {
      generatedLetters.push(allLetters.find(x => x.text === letter)!);
    }
  }

  return generatedLetters;
}

export function ensureVowels(size: number, generatedLetters: Letter[], alphabet: Alphabet) {
  const numVowelsAlready = generatedLetters.filter(letter => alphabet.vowels.includes(letter)).length;
  for (let i = numVowelsAlready; i < size - 1; ++i) {
    generatedLetters.push(alphabet.vowels[Math.trunc(Math.random() * alphabet.vowels.length)]);
  }
}

export function fillRemainingSlots(numGridSlots: number, generatedLetters: Letter[], allLetters: Letter[]) {
  for (let i = generatedLetters.length; i < numGridSlots; ++i) {
    let selectedLetter = allLetters[Math.trunc(Math.random() * allLetters.length)];
    let shouldRegenerate = false;
    if (selectedLetter.score >= 8) {
      // 60% chance to remove it.
      shouldRegenerate = Math.random() >= 0.6;
    } else if (selectedLetter.score >= 5) {
      // 30% chance to remove it.
      shouldRegenerate = Math.random() >= 0.3;
    }

    if (shouldRegenerate) {
      selectedLetter = allLetters[Math.trunc(Math.random() * allLetters.length)];
    }

    generatedLetters.push(selectedLetter);
  }
}

export function removeExcessVowels(numGridSlots: number, generatedLetters: Letter[], alphabet: Alphabet) {
  while (generatedLetters.filter(x => alphabet.vowels.includes(x)).length > numGridSlots * 0.4) {
    const firstVowelIndex = generatedLetters.findIndex(x => alphabet.vowels.includes(x));
    generatedLetters[firstVowelIndex] = alphabet.consonants[Math.trunc(Math.random() * alphabet.consonants.length)];
  }
}

export function randomizePositions(size: number, generatedLetters: Letter[], grid: IncompleteGrid) {
  for (let y = 0; y < size; ++y) {
    const columns: Letter[] = [];
    for (let x = 0; x < size; ++x) {
      const randomIndex = Math.trunc(Math.random() * generatedLetters.length);
      const letter = generatedLetters.splice(randomIndex, 1)[0];
      columns.push(letter);
    }

    grid.push(columns);
  }
}

export function ensureVowelInMiddle(vowels: Letter[], grid: Grid) {
  if (grid.length < 3 || grid[0].length < 3) {
    // There's no 'middle' of a 2x2 or smaller grid.
    return;
  }

  const numColumns = grid.length;
  const numRows = grid[0].length;

  const numColumnsToTrimOnEachSide = Math.trunc(Math.sqrt(numColumns));
  const numRowsToTrimOnEachSide = Math.trunc(Math.sqrt(numRows));

  const middleColumnStartIndex = numColumnsToTrimOnEachSide;
  const middleColumnEndIndex = numColumns - numColumnsToTrimOnEachSide - 1;
  const middleRowStartIndex = numRowsToTrimOnEachSide;
  const middleRowEndIndex = numRows - numRowsToTrimOnEachSide - 1;

  let hasVowelInMiddle = false;
  for (let y = middleRowStartIndex; y <= middleRowEndIndex && !hasVowelInMiddle; ++y) {
    for (let x = middleColumnStartIndex; x <= middleColumnEndIndex && !hasVowelInMiddle; ++x) {
      if (vowels.includes(grid[y][x])) {
        hasVowelInMiddle = true;
      }
    }
  }

  if (!hasVowelInMiddle && grid.length > 1) {
    const yInMiddle = Math.trunc(Math.random() * numColumnsToTrimOnEachSide) + middleColumnStartIndex;
    const xInMiddle = Math.trunc(Math.random() * numRowsToTrimOnEachSide) + middleRowStartIndex;
    const middleLetterToSwap = grid[yInMiddle][xInMiddle];
    let hasFoundVowelToSwap = false;
    for (let y = 0; y < numColumns && !hasFoundVowelToSwap; ++y) {
      for (let x = 0; x < numRows && !hasFoundVowelToSwap; ++x) {
        if (y >= middleColumnStartIndex && y <= middleColumnEndIndex && x >= middleRowStartIndex && x <= middleRowEndIndex) {
          continue;
        }

        const candidateLetter = grid[y][x];
        if (vowels.includes(candidateLetter)) {
          grid[yInMiddle][xInMiddle] = candidateLetter;
          grid[y][x] = middleLetterToSwap;
          hasFoundVowelToSwap = true;
        }
      }
    }
  }
}

export function createBonusSlot(grid: Grid) {
  // TODO
}

export function replaceLanguageQuirks(alphabet: Alphabet, grid: Grid) {
  if (!alphabet.quirks) {
    return;
  }

  for (let y = 0; y < grid.length; y++) {
    for (let x = 0; x < grid[y].length; x++) {
      for (let quirkKey in alphabet.quirks) {
        if (grid[y][x].text === quirkKey) {
          grid[y][x] = alphabet.quirks[quirkKey];
        }
      }
    }
  }
}

export function cullExcessMultiletterTiles(allLetters: Letter[], grid: Grid) {
  const Multiletters = grid.flat().filter(letter => letter.text.length > 1);
  while (Multiletters.length > 1) {
    const randomIndex = Math.trunc(Math.random() * Multiletters.length);
    const letter = Multiletters.splice(randomIndex, 1)[0];
    const randomLetterIndex = Math.trunc(Math.random() * allLetters.length);
    const randomLetter = allLetters[randomLetterIndex];
    letter.score = randomLetter.score;
    letter.text = randomLetter.text;
  }
}

export function clone(grid: Grid): IncompleteGrid {
  return grid.map(x => x.map(y => ({ ...y })));
}

export function cloneKnownProps(grid: Grid): IncompleteGrid {
  return grid.map(x => x.map(y => ({ text: y.text, score: y.score })));
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  isReturningUser?: boolean;
  isHighContrastMode?: boolean;
  isLeftHandedMode?: boolean;
  culture: string;
  isStatsDialogShowing: boolean;
}

const initialState: AppState = {
  isReturningUser: undefined,
  isHighContrastMode: undefined,
  isLeftHandedMode: undefined,
  culture: navigator.language || "en-AU",
  isStatsDialogShowing: false
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsReturningUser: (state, action: PayloadAction<boolean>) => {
      state.isReturningUser = action.payload;
    },
    setCulture: (state, action: PayloadAction<string>) => {
      state.culture = action.payload;
    },
    setIsHighContrastMode: (state, action: PayloadAction<boolean>) => {
      state.isHighContrastMode = action.payload;
    },
    setIsStatsDialogShowing: (state, action: PayloadAction<boolean>) => {
      state.isStatsDialogShowing = action.payload;
    },
    setIsLeftHandedMode: (state, action: PayloadAction<boolean>) => {
      state.isLeftHandedMode = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setIsReturningUser, setCulture, setIsHighContrastMode, setIsLeftHandedMode, setIsStatsDialogShowing } =
  appSlice.actions;

export default appSlice.reducer;

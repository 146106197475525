import React from "react";

import classes from "./BigButton.module.css";

export interface BigButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  additionalClasses?: string;
}

export const BigButton = ({ children, additionalClasses, ...otherProps }: BigButtonProps): JSX.Element => {
  return (
    <button className={`${classes.bigButton} ${additionalClasses || ""}`} {...otherProps}>
      {children}
    </button>
  );
};

import React from "react";

import appInsights from "../../ApplicationInsightsWrapper";
import { setIsStatsDialogShowing } from "../../AppSlice";
import { useAppDispatch, useResource } from "../../hooks";

import { ReactComponent as ShareIcon } from "../../assets/svgs/share_24dp.svg";

import Resources from "./ShareButtonResources.json";

import classes from "./ShareButton.module.css";

export interface ShareButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  shareData: ShareData;
}

export const ShareButton = ({ children, shareData, ...otherProps }: ShareButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const sharingNotSupportedText = useResource(Resources, "notSupported");
  const copiedSuccessfullyText = useResource(Resources, "copiedSuccessfully");
  return (
    <button
      className={classes.shareButton}
      {...otherProps}
      onClick={() => {
        appInsights.trackEvent({ name: "ShareButtonClicked" });
        if (navigator?.canShare && navigator?.canShare(shareData)) {
          navigator.share(shareData).then(() => {
            dispatch(setIsStatsDialogShowing(true));
            navigator?.clipboard
              ?.writeText((shareData.text || "") + "\n" + (shareData.url || ""))
              .catch(error => console.log(error));
          });
        } else {
          if (navigator?.clipboard?.writeText) {
            navigator.clipboard
              .writeText((shareData.text || "") + "\n" + (shareData.url || ""))
              .then(() => alert(copiedSuccessfullyText))
              .catch(error => console.log(error))
              .finally(() => {
                dispatch(setIsStatsDialogShowing(true));
              });
          } else {
            alert(sharingNotSupportedText);
            dispatch(setIsStatsDialogShowing(true));
          }
        }
      }}
    >
      <ShareIcon />
      {children}
    </button>
  );
};

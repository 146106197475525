import React from "react";

import { Game } from "./components/Game/Game";
import { Spinner } from "./components/Spinner/Spinner";
import { setIsDarkMode } from "./components/ThemeButton/ThemeButtonSlice";

import { GRID_SIZE } from "./AppConstants";
import { setIsReturningUser } from "./AppSlice";
import { useAppDispatch, useAppSelector, useCulture } from "./hooks";

import { getGridNumber } from "./features/Game/GridNumberCalculator";
import { generateGrid } from "./features/GridGenerator/GridGenerator";
import { type Grid, type GameGrid, type TitledGrid } from "./features/GridGenerator/Types";
import { ColorOverrides } from ".";

const rootElement = document.querySelector<HTMLElement | SVGElement>(":root");

const Admin = React.lazy(() => import("./features/Admin/Admin"));

function App() {
  const [gameGrid, setGameGrid] = React.useState<GameGrid>([]);
  const [dictionary, setDictionary] = React.useState<string[]>([]);
  const isDarkMode = useAppSelector((state) => state.themeButton.isDarkMode);
  const isHighContrastMode = useAppSelector((state) => state.app.isHighContrastMode);
  const dispatch = useAppDispatch();
  const culture = useCulture();
  let language = culture.split("-")[0];

  const gridNumber = +window.location?.pathname?.replace("/", "").split("/")[0] || getGridNumber(new Date());
  const isAdmin = window.location?.pathname?.localeCompare("/admin", undefined, { sensitivity: "base" }) === 0;

  // Temporary for now.
  if (language !== "en") {
    language = "en";
  }

  React.useEffect(() => {
    let fetchedDictionary: string[] = [];
    window
      .fetch(`/dictionaries/dictionary-${language}-v1.txt`, { method: "GET" })
      .then(async (text) => {
        fetchedDictionary = (await text.text()).split(/\r?\n/).filter((x) => x.length);
        setDictionary(fetchedDictionary);
        window
          .fetch(`/grids/${language}/${gridNumber}.json`, { method: "GET" })
          .then(async (text) => setGameGrid(await text.json()))
          .catch(async (reason) => {
            console.error(reason);
            setGameGrid(await generateGrid(GRID_SIZE, language, fetchedDictionary));
          });
      })
      .catch(async (reason) => {
        console.error(reason);
        setGameGrid(await generateGrid(GRID_SIZE, language, fetchedDictionary));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridNumber, language]);

  React.useEffect(() => {
    window?.setTimeout(() => dispatch(setIsReturningUser(true)), 5000);
  }, [dispatch]);

  React.useEffect(() => {
    if (rootElement && isDarkMode == null) {
      const rootStyle = getComputedStyle(rootElement);
      const isDark = rootStyle.getPropertyValue("--background").trim() === rootStyle.getPropertyValue("--dark").trim();
      if (isDark) {
        dispatch(setIsDarkMode(true));
      }
    }
  }, [dispatch, isDarkMode]);

  React.useEffect(() => {
    if (rootElement && !isHighContrastMode) {
      const rootStyle = getComputedStyle(rootElement);
      const darkColor = rootStyle.getPropertyValue("--dark");
      const lightColor = rootStyle.getPropertyValue("--light");
      const errorLightThemeColor = rootStyle.getPropertyValue("--error-light-theme");
      const errorDarkThemeColor = rootStyle.getPropertyValue("--error-dark-theme");
      const warningLightThemeColor = rootStyle.getPropertyValue("--warning-light-theme");
      const warningDarkThemeColor = rootStyle.getPropertyValue("--warning-dark-theme");

      rootElement.style.setProperty("--foreground", isDarkMode ? lightColor : darkColor);
      rootElement.style.setProperty("--background", isDarkMode ? darkColor : lightColor);
      rootElement.style.setProperty("--error", isDarkMode ? errorDarkThemeColor : errorLightThemeColor);
      rootElement.style.setProperty("--warning", isDarkMode ? warningDarkThemeColor : warningLightThemeColor);
    }
  }, [isDarkMode, isHighContrastMode]);

  React.useEffect(() => {
    if (rootElement) {
      const rootStyle = getComputedStyle(rootElement);
      const darkColor = rootStyle.getPropertyValue("--dark");
      const lightColor = rootStyle.getPropertyValue("--light");
      const errorLightThemeColor = rootStyle.getPropertyValue("--error-light-theme");
      const errorDarkThemeColor = rootStyle.getPropertyValue("--error-dark-theme");
      const warningLightThemeColor = rootStyle.getPropertyValue("--warning-light-theme");
      const warningDarkThemeColor = rootStyle.getPropertyValue("--warning-dark-theme");

      rootElement.style.setProperty("--foreground", isHighContrastMode ? "#ffff00" : isDarkMode ? lightColor : darkColor);
      rootElement.style.setProperty("--background", isHighContrastMode ? "#000000" : isDarkMode ? darkColor : lightColor);
      rootElement.style.setProperty(
        "--error",
        isHighContrastMode ? "#ff0000" : isDarkMode ? errorDarkThemeColor : errorLightThemeColor
      );
      rootElement.style.setProperty(
        "--warning",
        isHighContrastMode ? "#202020" : isDarkMode ? warningDarkThemeColor : warningLightThemeColor
      );
      rootElement.style.setProperty("--success", isHighContrastMode ? "#00ffff" : "#309611");
      rootElement.style.setProperty("--neutral", isHighContrastMode ? "#00ff00" : "#7f7f7f");
      rootElement.style.setProperty("--secondary", isHighContrastMode ? "#0000ff" : "#5c54ff");
      rootElement.style.setProperty("--primary", isHighContrastMode ? "#ff00ff" : "#e900ff");
    }
  }, [isHighContrastMode, isDarkMode]);

  const titleGrid = gameGrid as TitledGrid;
  const title = titleGrid.title;
  const themeWords = titleGrid.themeWords;
  const themeColors = titleGrid.colors;
  if (!isHighContrastMode && themeColors && rootElement) {
    setColors(rootElement, themeColors);
  }

  const grid = Array.isArray(gameGrid) ? (gameGrid as Grid) : (gameGrid as TitledGrid)?.grid;
  return !isAdmin ? (
    <Game dictionary={dictionary} grid={grid} title={title} themeWords={themeWords} gameNumber={gridNumber} language={language} />
  ) : (
    <React.Suspense fallback={<Spinner />}>
      <Admin dictionary={dictionary} language={language} />
    </React.Suspense>
  );
}

export default App;

function setColors(rootElement: HTMLElement | SVGElement, themeColors: ColorOverrides) {
  rootElement.style.setProperty("--neutral", themeColors.neutral);
  rootElement.style.setProperty("--foreground", themeColors.foreground);
  rootElement.style.setProperty("--background", themeColors.background);
  rootElement.style.setProperty("--primary", themeColors.primary);
  rootElement.style.setProperty("--primary-active", themeColors.primaryActive);
  rootElement.style.setProperty("--primary-hover", themeColors.primaryHover);
  rootElement.style.setProperty("--secondary", themeColors.secondary);
  rootElement.style.setProperty("--secondary-active", themeColors.secondaryActive);
  rootElement.style.setProperty("--secondary-hover", themeColors.secondaryHover);
  rootElement.style.setProperty("--success", themeColors.success);
  rootElement.style.setProperty("--warning", themeColors.warning);
  rootElement.style.setProperty("--error", themeColors.error);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ThemeButtonState {
  isDarkMode?: boolean;
}

const initialState: ThemeButtonState = {
  isDarkMode: undefined
};

export const themeButtonSlice = createSlice({
  name: "themeButtonComponent",
  initialState,
  reducers: {
    setIsDarkMode: (state, action: PayloadAction<boolean>) => {
      state.isDarkMode = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setIsDarkMode } = themeButtonSlice.actions;

export default themeButtonSlice.reducer;

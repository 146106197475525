import binarySearch from "binary-search";
import { Letter } from "../GridGenerator/Types";

export function calculateScore(word: Letter[], dictionary: string[], themeWords?: string[]): number {
  const proposedDictionaryWord = word.reduce((previous, current) => previous + current.text, "");
  if (proposedDictionaryWord.length < 3) {
    return 0;
  }

  const isWordFound = binarySearch(dictionary, proposedDictionaryWord, (left, right) =>
    left.localeCompare(right, undefined, { sensitivity: "base" })
  );

  if (isWordFound < 0 || isWordFound >= dictionary.length) {
    return 0;
  }

  const baseScores = word.reduce((previous, current) => previous + current.score, 0);
  let bonusScore = Math.trunc(Math.pow(2, proposedDictionaryWord.length - 3));
  if (bonusScore <= 1) {
    bonusScore = 0;
  }

  if (themeWords?.includes(proposedDictionaryWord)) {
    bonusScore += 15;
  }

  return baseScores + Math.min(100, bonusScore);
}

import { type Letter, type Alphabet } from "./Types";

export function getAlphabet(language: string): Alphabet {
  // Return a new copy each time so that the master can't accidentally be edited.
  const alphabet = Alphabets[language] || Alphabets["en"];
  return {
    vowels: alphabet.vowels.map(vowel => ({ ...vowel })),
    consonants: alphabet.consonants.map(consonant => ({ ...consonant })),
    quirks: alphabet.quirks
      ? Object.keys(alphabet.quirks).reduce((previous, current) => {
          previous[current] = { ...alphabet.quirks![current] };
          return previous;
        }, {} as Record<string, Letter>)
      : {}
  };
}

const Alphabets: { [language: string]: Alphabet } = {
  en: {
    vowels: [
      { text: "A", score: 2 },
      { text: "E", score: 1 },
      { text: "I", score: 2 },
      { text: "O", score: 2 },
      { text: "U", score: 3 }
    ],
    consonants: [
      { text: "B", score: 4 },
      { text: "C", score: 3 },
      { text: "D", score: 3 },
      { text: "F", score: 5 },
      { text: "G", score: 3 },
      { text: "H", score: 3 },
      { text: "J", score: 10 },
      { text: "K", score: 6 },
      { text: "L", score: 2 },
      { text: "M", score: 4 },
      { text: "N", score: 2 },
      { text: "P", score: 4 },
      { text: "Q", score: 10 },
      { text: "R", score: 2 },
      { text: "S", score: 1 },
      { text: "T", score: 1 },
      { text: "V", score: 6 },
      { text: "W", score: 4 },
      { text: "X", score: 8 },
      { text: "Y", score: 4 },
      { text: "Z", score: 10 }
    ],
    quirks: { Q: { text: "QU", score: 13 } }
  }
};

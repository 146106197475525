import React from "react";
import ReactDOM from "react-dom";

import { ReactComponent as CloseIcon } from "../../assets/svgs/close_24dp.svg";
import { useAppSelector } from "../../hooks";
import { SvgButton } from "../SvgButton/SvgButton";

import classes from "./Modal.module.css";

export interface ModalProps {
  isOpen: boolean;
  title?: string | null;
  children: React.ReactNode;
  onClose: () => void;
}

const ModalInner = (props: ModalProps): JSX.Element | null => {
  const [isFullyOpen, setIsFullyOpen] = React.useState(false);
  const isDarkMode = useAppSelector(state => state.themeButton.isDarkMode);
  const [backgroundColor, setBackgroundColor] = React.useState(isDarkMode ? "var(--dark)" : "var(--light)");
  const overlayRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    const rootElement = document.querySelector<HTMLElement | SVGElement>(":root");
    if (rootElement) {
      const rootStyle = getComputedStyle(rootElement);
      window.setTimeout(() => setBackgroundColor(rootStyle.getPropertyValue("--background").trim()), 20);
    }
  }, [isDarkMode]);

  React.useEffect(() => {
    setIsFullyOpen(props.isOpen);
  }, [props.isOpen]);

  if (!props.isOpen) {
    return null;
  }

  const close = () => {
    setIsFullyOpen(false);
    setTimeout(props.onClose, 100);
  };

  return (
    <div
      ref={overlayRef}
      className={classes.overlay}
      style={{ display: props.isOpen ? "flex" : "none", backgroundColor: `${backgroundColor}aa` }}
      onClick={e => {
        if (e.target === overlayRef.current) {
          close();
        }
      }}
    >
      <div className={`${classes.modal} ${isFullyOpen ? classes.finishedOpening : ""}`}>
        <div>
          <div className={classes.titleBar}>
            <h3 className={classes.titleText}>{props.title}</h3>
            <SvgButton style={{ flex: "0 1 auto", position: "absolute", right: 0 }} onClick={close}>
              <CloseIcon />
            </SvgButton>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const Modal = (props: ModalProps) =>
  ReactDOM.createPortal(<ModalInner {...props} />, document.getElementById("root") || document.body);

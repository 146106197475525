import React from "react";

import { useResource } from "../../hooks";

import { BigButton } from "../BigButton/BigButton";
import { Tile } from "../Tile/Tile";
import { NotCrawlers } from "../NotCrawlers/NotCrawlers";

import NormalPointerImage from "../../assets/hand-pointing-angled-normal-small.png";
import ActivePointerImage from "../../assets/hand-pointing-angled-active-small.png";

import classes from "./InstructionsDialogContents.module.css";

import Resources from "./InstructionsDialogContentsResources.json";
import { getAlphabet } from "../../features/GridGenerator/Alphabets";

const EXAMPLE_ALPHABET = getAlphabet("en");
const ALL_EXAMPLE_LETTERS = EXAMPLE_ALPHABET.consonants.concat(EXAMPLE_ALPHABET.vowels);
const EXAMPLE_LETTERS = ["S", "C", "R", "A", "J", "E", "B", "M", "H", "F", "L", "L", "A", "E", "O", "D"].map(
  text => ALL_EXAMPLE_LETTERS.find(x => x.text === text)!
);

export const InstructionsDialogContents = ({ onClose }: { onClose: () => void }): JSX.Element => {
  const [activeIndexes, setActiveIndexes] = React.useState<number[]>([]);
  const [pointerTransform, setPointerTransform] = React.useState("translate(0, 0.25em)");
  const [animationTrigger, setAnimationTrigger] = React.useState(0);

  React.useEffect(() => {
    const timeoutIds: number[] = [];
    setActiveIndexes([]);
    setPointerTransform("translate(0, 0.25em)");
    for (let i = 1; i <= 5; i++) {
      const timeoutId = window?.setTimeout(() => {
        if (i === 1) {
          setActiveIndexes([8]);
        } else if (i === 2) {
          setActiveIndexes([8, 5]);
          setPointerTransform("translate(1.5em, -0.5em)");
        } else if (i === 3) {
          setActiveIndexes([8, 5, 10]);
          setPointerTransform("translate(2.75em, 0.5em)");
        } else if (i === 4) {
          setActiveIndexes([8, 5, 10, 11]);
          setPointerTransform("translate(3.25em, 0.5em)");
        } else {
          setActiveIndexes([8, 5, 10, 11, 14]);
          setPointerTransform("translate(2.75em, 1.25em)");
        }
      }, i * 500 + 1000);

      timeoutIds.push(timeoutId);
    }

    return function cleanup() {
      for (const timeoutId of timeoutIds) {
        window?.clearTimeout(timeoutId);
      }
    };
  }, [animationTrigger]);

  return (
    <>
      <div className={classes.paragraph}>
        {useResource(Resources, "blurbStart")}
        <b>{useResource(Resources, "blurbBold")}</b>
        {useResource(Resources, "blurbEnd")}
      </div>
      <div className={classes.paragraph}>{useResource(Resources, "bonusPoints")}</div>
      <div className={classes.paragraph}>{useResource(Resources, "connectingLetters")}</div>
      <hr></hr>
      <NotCrawlers>
        <h4>{useResource(Resources, "example")}</h4>
        <div className={classes.exampleGridContainer} onClick={() => setAnimationTrigger(animationTrigger + 1)}>
          <div className={classes.exampleGrid}>
            {EXAMPLE_LETTERS.map((letter, index) => {
              return <Tile key={index} letter={letter} autoFlip={index * 20} isActive={activeIndexes.indexOf(index) >= 0} />;
            })}
          </div>
          <img
            className={classes.pointerImage}
            src={activeIndexes.length === 0 ? NormalPointerImage : ActivePointerImage}
            style={{ transform: pointerTransform }}
            alt="A hand pointing at the letters in the grid."
          />
          <div style={{ textAlign: "start" }}>{useResource(Resources, "holdAndDragExplanation")}</div>
        </div>
        <hr></hr>
      </NotCrawlers>
      <div className={classes.spacer} />
      <p>{useResource(Resources, "shareWithFriends")}</p>
      <div style={{ textAlign: "center" }}>
        <BigButton style={{ fontSize: "var(--font-size-normal)" }} onClick={onClose}>
          {useResource(Resources, "close")}
        </BigButton>
      </div>
    </>
  );
};

import React from "react";

import { useAppDispatch, useAppSelector, useDimensions, useResource } from "../../hooks";
import { setIsStatsDialogShowing } from "../../AppSlice";

import { Modal } from "../Modal/Modal";
import { SvgButton } from "../SvgButton/SvgButton";
import { MenuDialogContents } from "../MenuDialogContents/MenuDialogContents";
import { SettingsDialogContents } from "../SettingsDialogContents/SettingsDialogContents";
import { InstructionsDialogContents } from "../InstructionsDialogContents/InstructionsDialogContents";
import { Statistics } from "../Statistics/Statistics";

import { ReactComponent as SettingsIcon } from "../../assets/svgs/settings_24dp.svg";
import { ReactComponent as MenuIcon } from "../../assets/svgs/menu_24dp.svg";
import { ReactComponent as StatisticsIcon } from "../../assets/svgs/statistics_24dp.svg";
import { ReactComponent as HelpIcon } from "../../assets/svgs/help_outline_24dp.svg";

import classes from "./MenuBar.module.css";

import Resources from "./MenuBarResources.json";

export const MenuBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isDesktop = useDimensions();
  const isReturningUser = useAppSelector(state => state.app.isReturningUser);
  const isStatsDialogShowing = useAppSelector(state => state.app.isStatsDialogShowing);
  const [isMenuModalOpen, setIsMenuModalOpen] = React.useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = React.useState(!isReturningUser);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = React.useState(false);

  return (
    <div className={`${classes.menuBarWrapper} ${isDesktop ? classes.desktop : ""}`}>
      <Modal title={useResource(Resources, "menuTitle")} isOpen={isMenuModalOpen} onClose={() => setIsMenuModalOpen(false)}>
        <MenuDialogContents />
      </Modal>
      <Modal
        title={useResource(Resources, "instructionsTitle")}
        isOpen={isInstructionsModalOpen}
        onClose={() => setIsInstructionsModalOpen(false)}
      >
        <InstructionsDialogContents onClose={() => setIsInstructionsModalOpen(false)} />
      </Modal>
      <Modal
        title={useResource(Resources, "statisticsTitle")}
        isOpen={isStatsDialogShowing}
        onClose={() => dispatch(setIsStatsDialogShowing(false))}
      >
        <Statistics />
      </Modal>
      <Modal
        title={useResource(Resources, "settingsTitle")}
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
      >
        <SettingsDialogContents />
      </Modal>
      <div className={classes.menuBarStart}>
        <SvgButton onClick={() => setIsMenuModalOpen(true)}>
          <MenuIcon />
        </SvgButton>
        <SvgButton onClick={() => setIsInstructionsModalOpen(true)}>
          <HelpIcon />
        </SvgButton>
      </div>
      <div className={classes.appTitle}>
        <strong>Scrambl</strong>
      </div>
      <div className={classes.menuBarEnd}>
        <span className={classes.spacer} />
        <SvgButton onClick={() => dispatch(setIsStatsDialogShowing(true))}>
          <StatisticsIcon />
        </SvgButton>
        <SvgButton onClick={() => setIsSettingsModalOpen(true)}>
          <SettingsIcon />
        </SvgButton>
      </div>
    </div>
  );
};

import classes from "./SvgButton.module.css";

export interface SvgButtonProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick: () => void;
}

export const SvgButton = (props: SvgButtonProps) => (
  <div className={classes.icon} onClick={props.onClick} style={{ ...props.style }}>
    {props.children}
  </div>
);

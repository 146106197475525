import React from "react";

import { Letter, type Grid } from "../../features/GridGenerator/Types";
import { Tile } from "../Tile/Tile";

import classes from "./LetterGrid.module.css";

export interface LetterGridProps {
  rotation: number;
  letters: Grid;
  isDisabled: boolean;
  successLetters: Letter[];
  duplicateLetters: Letter[];
  failureLetters: Letter[];
  onWordFinished?: (word: Letter[]) => void;
}

export const LetterGrid = ({
  rotation,
  letters,
  successLetters,
  duplicateLetters,
  failureLetters,
  isDisabled,
  onWordFinished
}: LetterGridProps): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = React.useState(`2rem`);
  const [activatedTiles, setActivatedTiles] = React.useState<string[]>([]);
  const tileRefs: React.Ref<React.Ref<HTMLDivElement>[]> = React.useRef([]);
  for (let i = 0; i < letters.length; i++) {
    for (let j = 0; j < letters[i].length; j++) {
      const tileRef = React.createRef<HTMLDivElement>();
      tileRefs.current!.push(tileRef);
    }
  }

  React.useEffect(() => {
    const listener = () => {
      const MAX_FONT_SIZE = 64;
      const MIN_FONT_SIZE = 16;
      const smallestDimension = Math.min(
        containerRef.current?.clientHeight || MIN_FONT_SIZE,
        containerRef.current?.clientWidth || MIN_FONT_SIZE
      );
      const desiredFontSize = (smallestDimension || 40) / letters.length;
      setFontSize(Math.min(desiredFontSize, MAX_FONT_SIZE) + "px");
    };

    window?.addEventListener("resize", listener);

    listener();
    return function cleanup() {
      window?.removeEventListener("resize", listener);
    };
  }, [letters.length]);

  function activateTilesAt(location: { pageX: number; pageY: number }) {
    if (!isDisabled) {
      const tileElementsUnder = document
        .elementsFromPoint(location.pageX, location.pageY - window.scrollY)
        .filter(x => x.closest(".tile"));
      if (tileElementsUnder?.length > 0) {
        const key = tileElementsUnder[0].closest(".tile")?.getAttribute("data-key");
        if (key) {
          setActivatedTiles([key]);
        }
      }
    }
  }

  function moveOverTilesAt(location: { pageX: number; pageY: number }) {
    if (!isDisabled) {
      const textElementsUnder = document
        .elementsFromPoint(location.pageX, location.pageY - window.scrollY)
        .filter(x => x.closest(".tileText"));
      if (textElementsUnder?.length > 0) {
        const key = textElementsUnder[0].closest(".tile")?.getAttribute("data-key");
        if (key) {
          if (!activatedTiles.includes(key)) {
            // Check that the new key is adjacent to the last key. If it is, we can activate the new tile.
            if (activatedTiles.length >= 1) {
              const lastActivatedCoordinates = activatedTiles[activatedTiles.length - 1].split("-");
              const potentialCoordinates = key.split("-");
              if (isAdjacent(lastActivatedCoordinates as [string, string], potentialCoordinates as [string, string])) {
                setActivatedTiles([...activatedTiles, key]);
              }
            }
          } else if (activatedTiles.indexOf(key) === activatedTiles.length - 2) {
            // Remove the last key if the hovered key is the 2nd last item. It means the user has backtracked.
            setActivatedTiles(activatedTiles.slice(0, activatedTiles.length - 1));
          }
        }
      }
    }
  }

  function finishWord() {
    if (!isDisabled) {
      setActivatedTiles([]);
      const completedCoordinates = getCoordinateList(activatedTiles);
      onWordFinished?.(completedCoordinates.map(coord => letters[coord[1]][coord[0]]));
    }
  }

  return (
    <div
      ref={containerRef}
      className={`${classes.letterGrid} ${isDisabled ? classes.disabled : ""}`}
      style={{
        gridTemplateRows: `repeat(${letters?.length}, 1fr)`,
        gridTemplateColumns: `repeat(${letters[0]?.length}, 1fr)`,
        transform: `rotate(${rotation}deg)`,
        display: letters?.length && letters?.[0]?.length ? undefined : "none",
        fontSize
      }}
      onMouseDown={e => {
        activateTilesAt(e);
      }}
      onMouseOver={e => moveOverTilesAt(e)}
      onMouseUp={e => finishWord()}
      onTouchStart={e => {
        const firstTouch = e.touches[0];
        activateTilesAt(firstTouch);
      }}
      onTouchMove={e => {
        const firstTouch = e.touches[0];
        moveOverTilesAt(firstTouch);
      }}
      onTouchEnd={finishWord}
    >
      {letters.map((row, rowIndex) =>
        row.map((letter, columnIndex) => {
          const key = `${columnIndex}-${rowIndex}`;
          return (
            <Tile
              key={key}
              dataKey={key}
              letter={letter}
              autoFlip={rowIndex * columnIndex * 10}
              rotation={-rotation}
              isDisabled={isDisabled}
              isActive={activatedTiles.includes(key)}
              isSuccess={successLetters.includes(letter) && activatedTiles?.length === 0}
              isDuplicate={duplicateLetters.includes(letter) && activatedTiles?.length === 0}
              isFailure={failureLetters.includes(letter) && activatedTiles?.length === 0}
            />
          );
        })
      )}
    </div>
  );
};

function getCoordinateList(keys: string[]) {
  return keys.map(key => {
    const coords = key.split("-");
    const rowIndex = +coords[0];
    const columnIndex = +coords[1];
    return [rowIndex, columnIndex] as [number, number];
  });
}

function isAdjacent(coord1: [string, string], coord2: [string, string]): boolean {
  const coord1Numbers = [+coord1[0], +coord1[1]];
  const coord2Numbers = [+coord2[0], +coord2[1]];
  const isAdjacent =
    coord1Numbers[0] >= coord2Numbers[0] - 1 &&
    coord1Numbers[0] <= coord2Numbers[0] + 1 &&
    coord1Numbers[1] >= coord2Numbers[1] - 1 &&
    coord1Numbers[1] <= coord2Numbers[1] + 1;
  return isAdjacent;
}

import React from "react";
import DarkModeToggle from "react-dark-mode-toggle";

import { setIsHighContrastMode, setIsLeftHandedMode } from "../../AppSlice";
import { useAppDispatch, useAppSelector, useResource } from "../../hooks";
import { SwitchWrapper } from "../SwitchWrapper/SwitchWrapper";
import { setIsDarkMode } from "../ThemeButton/ThemeButtonSlice";

import classes from "./SettingsDialogContents.module.css";

import Resources from "./SettingsDialogContentsResources.json";

export const SettingsDialogContents = (): JSX.Element => {
  const isDarkMode = useAppSelector(state => state.themeButton.isDarkMode);
  const isHighContrastMode = useAppSelector(state => state.app.isHighContrastMode);
  const isLeftHandedMode = useAppSelector(state => state.app.isLeftHandedMode);
  const [switchOnColor, setSwitchOnColor] = React.useState("--success");
  const [switchOffColor, setSwitchOffColor] = React.useState("--neutral");
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    window.setTimeout(() => {
      setSwitchOnColor(isHighContrastMode ? "--primary" : "--success");
      setSwitchOffColor(isHighContrastMode ? "--warning" : "--neutral");
    }, 20);
  }, [isHighContrastMode]);

  return (
    <div className={classes.contents}>
      <div>
        {useResource(Resources, "darkThemeLabel")}
        <br />
        <span className="muted">{useResource(Resources, "darkThemeCaption")}</span>
      </div>
      <div className={classes.darkModeToggleContainer}>
        <DarkModeToggle
          checked={isDarkMode}
          size="4rem"
          onChange={isChecked => {
            dispatch(setIsDarkMode(isChecked));
            if (!isChecked) {
              dispatch(setIsHighContrastMode(false));
            }
          }}
        />
      </div>
      <div>
        {useResource(Resources, "highContrastLabel")}
        <br />
        <span className="muted">{useResource(Resources, "highContrastCaption")}</span>
      </div>
      <label className={classes.flexEnd} title={useResource(Resources, "highContrastSwitchLabel")}>
        <SwitchWrapper
          checked={isHighContrastMode || false}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={switchOnColor}
          offColor={switchOffColor}
          onChange={isChecked => {
            dispatch(setIsHighContrastMode(isChecked));
            if (isChecked) {
              dispatch(setIsDarkMode(true));
            }
          }}
        />
      </label>

      <div>
        {useResource(Resources, "leftHandedLabel")}
        <br />
        <span className="muted">{useResource(Resources, "leftHandedCaption")}</span>
      </div>
      <label className={classes.flexEnd} title={useResource(Resources, "leftHandedSwitchLabel")}>
        <SwitchWrapper
          checked={isLeftHandedMode || false}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={switchOnColor}
          offColor={switchOffColor}
          onChange={isChecked => {
            dispatch(setIsLeftHandedMode(isChecked));
          }}
        />
      </label>
    </div>
  );
};

import { useResource } from "../../hooks";

import { ReactComponent as FacebookIcon } from "../../assets/svgs/facebook.svg";

import classes from "./MenuDialogContents.module.css";

import Resources from "./MenuDialogContentsResources.json";

export const MenuDialogContents = (): JSX.Element => {
  return (
    <div className={classes.contents}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FacebookIcon style={{ height: "2em", width: "2em", marginRight: "1em" }} />
        <a href="https://www.facebook.com/scramblgame" rel="noopener noreferrer">
          {useResource(Resources, "visitOnFacebook")}
        </a>
      </div>
    </div>
  );
};

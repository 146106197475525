import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import appInsights from "./ApplicationInsightsWrapper";
import { MenuBar } from "./components/MenuBar/MenuBar";

import store from "./store";
import App from "./App";

import "./index.css";

const root = document.getElementById("root");

export type ColorOverrides = {
  neutral: string;
  primary: string;
  primaryHover: string;
  primaryActive: string;
  secondary: string;
  secondaryHover: string;
  secondaryActive: string;
  foreground: string;
  background: string;
  success: string;
  error: string;
  warning: string;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MenuBar />
      <App />
    </Provider>
  </React.StrictMode>,
  root
);
appInsights.trackPageView();

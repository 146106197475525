import { configureStore } from "@reduxjs/toolkit";

import { gameSlice } from "./components/Game/GameSlice";
import { statisticsSlice } from "./components/Statistics/StatisticsSlice";
import { themeButtonSlice } from "./components/ThemeButton/ThemeButtonSlice";

import { appSlice } from "./AppSlice";

let persistId: number = 0;

const persistedState = localStorage.getItem("state");

const store = configureStore({
  preloadedState: persistedState ? JSON.parse(persistedState) : undefined,
  reducer: {
    themeButton: themeButtonSlice.reducer,
    app: appSlice.reducer,
    statistics: statisticsSlice.reducer,
    game: gameSlice.reducer
  }
});

store.subscribe(() => {
  clearTimeout(persistId);
  persistId = window.setTimeout(() => localStorage.setItem("state", JSON.stringify(store.getState())), 200);
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

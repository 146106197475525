import { useResource, useDimensions, useAppSelector } from "../../hooks";
import classes from "./Countdown.module.css";

import Resources from "./CountdownResources.json";

export const Countdown = ({ timeRemaining }: { timeRemaining: number }): JSX.Element => {
  const isHighContrast = useAppSelector(state => state.app.isHighContrastMode);
  const timeUpText = useResource(Resources, "timeUp");
  const { isLandscape } = useDimensions();
  const secondsRemaining = Math.max(timeRemaining / 1000, 0);
  return (
    <div
      className={`${classes.countdown} ${secondsRemaining > 5 || isHighContrast ? "" : classes.timeLow} ${
        secondsRemaining > 1 ? "" : classes.timeUp
      } ${isLandscape ? classes.landscape : ""}`}
    >
      {secondsRemaining <= 0 ? timeUpText : secondsRemaining > 5 ? Math.trunc(secondsRemaining) : secondsRemaining.toFixed(2)}
    </div>
  );
};

import { ApplicationInsights } from "@microsoft/applicationinsights-web";

class ApplicationInsightsWrapper {
  public static appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: "19a28a37-8d55-4e7e-9ea9-adb21bf848b7"
      /* ...Other Configuration Options... */
    }
  });

  public static loadedAppInsights = ApplicationInsightsWrapper.appInsights.loadAppInsights();
}

export default ApplicationInsightsWrapper.appInsights;

import Switch, { ReactSwitchProps } from "react-switch";

export const SwitchWrapper = (props: Readonly<ReactSwitchProps>) => {
  const { onColor, offColor, ...rest } = props;
  return <Switch {...rest} onColor={getAsHexCode(props.onColor)} offColor={getAsHexCode(props.offColor)} />;
};

function getAsHexCode(color: string | undefined): string | undefined {
  if (color) {
    if (color.startsWith("#")) {
      return color;
    } else if (color.startsWith("--")) {
      const rootElement = document?.querySelector<HTMLElement>(":root");
      if (rootElement) {
        const rootStyle = getComputedStyle(rootElement);
        const returnVal = rootStyle?.getPropertyValue(color)?.trim();
        return returnVal;
      }
    } else {
      return color;
    }
  }

  return "#ffffff";
}

import React from "react";
import { Letter } from "../../features/GridGenerator/Types";
import { useAppSelector } from "../../hooks";

import classes from "./Tile.module.css";

export interface TileProps {
  dataKey?: string;
  letter: Letter;
  rotation?: number;
  isShowing?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isSuccess?: boolean;
  isDuplicate?: boolean;
  isFailure?: boolean;
  autoFlip?: false | number;
}

export const Tile = ({
  dataKey,
  letter,
  rotation,
  isShowing,
  isActive,
  isDisabled,
  isSuccess,
  isDuplicate,
  isFailure,
  autoFlip
}: TileProps): JSX.Element => {
  const [isFlipping, setIsFlipping] = React.useState(false);
  const [isFlipped, setIsFlipped] = React.useState(isShowing);
  const isHighContrast = useAppSelector(state => state.app.isHighContrastMode);

  React.useEffect(() => {
    if (autoFlip !== false) {
      window?.setTimeout(() => {
        setIsFlipping(true);
        window?.setTimeout(() => {
          setIsFlipped(true);
        }, 50);
      }, autoFlip);
    }
  }, [autoFlip]);

  React.useEffect(() => {
    setIsFlipping(false);
    setIsFlipped(isShowing);
  }, [isShowing]);

  return (
    <div
      className={`tile ${classes.tile} ${isFlipping ? classes.flipping : ""} ${isFlipped ? classes.flipped : ""} ${
        isActive ? classes.active : ""
      } ${isDisabled ? classes.disabled : ""} ${isFailure ? classes.failure : ""} ${isDuplicate ? classes.duplicate : ""} ${
        isSuccess ? classes.success : ""
      } ${isHighContrast ? classes.highContrast : ""}`}
      data-key={dataKey}
    >
      <div className={classes.rotationContainer} style={{ transform: `rotate(${rotation}deg)` }}>
        {isFlipped ? (
          <>
            <div className={`tileText ${classes.tileText}`}>
              {letter.text[0]}
              {letter.text.length > 1 && <span className={classes.additionalCharacters}>{letter.text.substring(1)}</span>}
            </div>
            <span className={classes.score}>{letter.score}</span>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

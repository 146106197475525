import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CompletedGame {
  language: string;
  gameNumber: number;
  bestWord: string;
  bestWordScore: number;
  score: number;
}

export interface GameState {
  completedGames: CompletedGame[];
}

const initialState: GameState = {
  completedGames: []
};

export const gameSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    addCompletedGame: (state, action: PayloadAction<CompletedGame>) => {
      if (!state.completedGames) {
        state.completedGames = [];
      }

      if (!state.completedGames.find(x => x.language === action.payload.language && x.gameNumber === action.payload.gameNumber)) {
        state.completedGames.push(action.payload);
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const { addCompletedGame } = gameSlice.actions;

export default gameSlice.reducer;

import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { RootState, AppDispatch } from "./store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDimensions = (): { isDesktop: boolean; isLandscape: boolean } => {
  const [isDesktop, setIsDesktop] = React.useState(getIsDesktop());
  const [isLandscape, setIsLandscape] = React.useState(getIsLandscape());

  React.useEffect(() => {
    const listener = (e: TransitionEvent) => {
      if (e.propertyName === "color") {
        const isDesktop = getIsDesktop();
        setIsDesktop(isDesktop);
        const isLandscape = getIsLandscape();
        setIsLandscape(isLandscape);
      }
    };

    window?.addEventListener("transitionend", listener);
    const cleanup = () => window?.removeEventListener("transitionend", listener);
    return cleanup;
  }, []);

  return { isDesktop, isLandscape };
};

const getIsDesktop = () => getComputedStyle(document?.documentElement)?.getPropertyValue("--is-desktop")?.trim() === "1";
const getIsLandscape = () => getComputedStyle(document?.documentElement)?.getPropertyValue("--is-landscape")?.trim() === "1";

export const useCulture = (): string => {
  return useAppSelector(state => state.app.culture) || navigator.language || "en-AU";
};

type RegionResource = { [key: string]: string };
type LanguageResource = { [key: string]: RegionResource | string };
type Resource = { [key: string]: LanguageResource };

export const useResource = <T extends Resource>(resources: T, key: keyof T): string | undefined => {
  const culture = useCulture();
  const cultureParts = culture.split("-");
  const parentLanguage = cultureParts[0];
  let region = "";
  if (cultureParts.length > 1) {
    region = cultureParts[1];
  }

  return getResource(resources, key, parentLanguage, region);
};

const getResource = <T extends Resource>(resources: T, key: keyof T, language: string, region?: string): string | undefined => {
  const languageLookup = resources[key];
  if (!languageLookup) {
    return undefined;
  }

  const languageResource = languageLookup[language];
  if (!languageResource && language !== "en") {
    return getResource(resources, key, "en");
  }

  if (typeof languageResource === "string") {
    return languageResource;
  }

  return languageResource[region || ""] || languageResource["default"] || getResource(resources, key, "en");
};

import React from "react";
import dayjs from "dayjs";

import { useResource } from "../../hooks";

import classes from "./CountdownToNextGame.module.css";

import Resources from "./CountdownToNextGameResources.json";
import { getGridNumber } from "../../features/Game/GridNumberCalculator";

export const CountdownToNextGame = ({
  showingGameNumber,
  style
}: {
  showingGameNumber: number;
  style?: React.CSSProperties;
}): JSX.Element => {
  const [timeRemaining, setTimeRemaining] = React.useState("");

  React.useEffect(() => {
    let intervalId = window.setInterval(() => {
      const now = dayjs();
      const tomorrow = dayjs(now.add(1, "day").startOf("day"));
      const durationHours = tomorrow.diff(now, "hour");
      const durationMinutes = tomorrow.diff(now, "minute") - 60 * durationHours;
      const durationSeconds = tomorrow.diff(now, "second") - 60 * durationMinutes - 3600 * durationHours;

      setTimeRemaining(`${durationHours}:${("0" + durationMinutes).slice(-2)}:${("0" + durationSeconds).slice(-2)}`);

      const currentGameNumber = getGridNumber(new Date());
      if ((!window?.location.pathname || window?.location.pathname === "/") && showingGameNumber !== currentGameNumber) {
        window?.location.reload();
      }
    }, 1000);

    return function cleanUp() {
      window.clearInterval(intervalId);
    };
  }, [showingGameNumber]);

  return (
    <h3 className={classes.countdownToNextGame} style={style || {}}>
      {useResource(Resources, "nextScrambl")}
      <br />
      <span className={classes.countdown}>{timeRemaining}</span>
    </h3>
  );
};
